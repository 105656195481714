import type { MaybeRef } from '~/ts/types/common'

export const observeElementMutation = (
    target: MaybeRef<HTMLElement> | MaybeRef<HTMLElement>[],
    callback: MutationCallback,
    options?: MutationObserverInit,
): VoidFunction => {
    const { window } = getClientContext()
    const isSupported = useSupported(() => window && ('MutationObserver' in window))

    let observer: MutationObserver | undefined

    const cleanup = (): void => {
        if (observer) {
            observer.disconnect()

            observer = undefined
        }
    }

    const targets = computed(() =>
        isArray(target)
            ? target.map(el => unrefElement(el))
            : [ unrefElement(target) ],
    )

    const stopWatch = watch(
        targets,
        (els) => {
            cleanup()

            if (isSupported.value && window && els.length) {
                observer = new MutationObserver(callback)

                for (const _el of els) {
                    if (_el) {
                        observer!.observe(_el, options)
                    }
                }
            }
        },
        {
            flush: 'post',
            immediate: true,
        },
    )

    const stop = () => {
        cleanup()
        stopWatch()
    }

    tryOnScopeDispose(stop)

    return stop
}
